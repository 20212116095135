<template>
  <action-dispatcher
    ref="dispatcher"
    action="classes/getClass"
    :parameters="$route.query.turma"
    @success="success"
  >
    <div class="view-register">
      <div class="row mb-4 text-center">
        <div class="col-10 mx-auto">
          <h3 class="d-none d-lg-block">Instituto Eurico Bergsten</h3>
          <h4 class="d-lg-none">Instituto Eurico Bergsten</h4>
          <router-link :to="{ name: 'login' }">Fazer login</router-link>
        </div>
      </div>

      <h5 class="text-center">{{ class_name }}</h5>

      <form @submit.prevent="submit" class="pt-4">
        <h4 class="text-dark mb-4">
          Criar conta
        </h4>

        <form-group
          v-model="form.name"
          id="name"
          label="Nome completo"
          type="text"
          placeholder="Digite o seu nome"
          :errors="errors.name"
        />

        <form-group
          v-model="form.email"
          id="email"
          type="email"
          label="E-mail"
          placeholder="Digite o seu e-mail"
          :errors="errors.email"
        />

        <form-group
          v-model="form.password"
          id="password"
          type="password"
          label="Senha"
          placeholder="Crie uma senha"
          :errors="errors.password"
        />

        <form-group
          v-model="form.region"
          id="region"
          select
          label="Você é membro da comunidade videira em qual região?"
          placeholder="Selecione..."
          :errors="errors.region"
          :options="[
            { id: 'Feira de Santana', name: 'Feira de Santana' },
            { id: 'Panambi', name: 'Panambi' },
            { id: 'Miguel Calmon', name: 'Miguel Calmon' },
            { id: 'Jacobina', name: 'Jacobina' },
            { id: 'Sertão', name: 'Sertão' },
            { id: 'Aluno Externo', name: 'Aluno Externo' }
          ]"
        />

        <b-form-checkbox
          id="terms"
          v-model="terms"
          name="terms"
          :value="true"
          class="my-3"
        >
          Li e concordo com os
          <router-link target="_blank" :to="{ name: 'terms' }">
            Termos de uso
          </router-link>
        </b-form-checkbox>

        <div class="text-center pt-2">
          <button
            type="submit"
            class="btn btn-primary btn-fixed-size"
            :disabled="!terms || loading"
          >
            <loading :show="loading">Criar conta</loading>
          </button>
        </div>
      </form>
    </div>
    <template #error>
      <p class="text-secondary text-center">
        Periodo de cadastro encerrado <br />
        Para saber mais, entre em contato com alguém responsável
      </p>
    </template>
  </action-dispatcher>
</template>

<script>
import { formFields, isValid } from "@/functions/helpers.js";
import moment from "moment";

export default {
  data() {
    return {
      ...formFields(["name", "email", "password", "region"]),
      classInfo: null,
      terms: false,
      loading: false
    };
  },
  computed: {
    isOutOfDate() {
      if (this.classInfo && this.classInfo.deadline) {
        return moment(this.classInfo.deadline, "DD/MM/YYYY").isAfter(moment());
      }

      return true;
    },
    class_name() {
      if (this.classInfo) {
        return `${this.classInfo.name}`;
      }

      return "";
    }
  },
  methods: {
    success(data) {
      this.classInfo = data;
      if (moment().isAfter(moment(this.classInfo.deadline, "DD/MM/YYYY"))) {
        this.$refs.dispatcher.error = true;
      }
    },
    submit() {
      this.$message.hide();

      if (!this.form?.name || !this.form?.name.length) {
        this.$message.warning("Por favor, preencha seu nome");
        return;
      }

      if (!this.form.email || !isValid(this.form.email, "email")) {
        this.$message.warning("Por favor, digite um email válido");
        return;
      }

      if (!this.form.password || this.form.password.length < 8) {
        this.$message.warning(
          "Por favor, digite uma senha válida de pelo menos 6 dígitos"
        );
        return;
      }

      this.loading = true;
      const payload = { ...this.form };

      if (this.classInfo) {
        payload.class = this.classInfo.id;
      }

      this.errors = {};

      this.$store
        .dispatch("user/register", payload)
        .then(() => {
          this.$router.replace({ name: "home" });
        })
        .catch(error => {
          this.$message.error("Erro ao tentar realizar o cadastro.");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-register {
  width: 100%;
}
</style>
